import { supabase } from "../supabaseClient";

export const fetchUsers = async () => {
  const { data, error } = await supabase
    .from("user")
    .select(
      "id, username, email, role_id, role!inner(label), locked, last_login, validated"
    );

  if (error) {
    console.error("Error fetching users:", error.message);
    return [];
  }

  return data.map((user) => ({
    id: user.id,
    username: user.username,
    email: user.email,
    role: user.role.label,
    locked: user.locked,
    last_login: user.last_login,
    validated: user.validated,
  }));
};
