import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://siprvalztvutmkibmzsh.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNpcHJ2YWx6dHZ1dG1raWJtenNoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTg3MTM3MjksImV4cCI6MjAzNDI4OTcyOX0.zkH_KqSF0K5-D0NDUiDiZNCtWOpNaDyXms-FmrotsF4";

export const supabase = createClient(supabaseUrl, supabaseKey, {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
