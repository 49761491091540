import React from "react";

const Settings = () => {
  return (
    <div>
      <h2>Settings</h2>
      <p>Configure your settings here.</p>
    </div>
  );
};

export default Settings;
