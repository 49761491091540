import React from "react";
import "../styles/assetTypeButton.css";

const AssetTypeButton = ({ type, selected, onClick }) => {
  return (
    <button
      className={`asset-type-button ${selected ? "selected" : ""}`}
      onClick={() => onClick(type.id)}
    >
      {type.name}
    </button>
  );
};

export default AssetTypeButton;
