import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { fetchRoles } from "../functions/fetchRoles";
import "./AddUserForm.css";

const AddUserForm = () => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const getRoles = async () => {
      const rolesData = await fetchRoles();
      setRoles(rolesData);
    };
    getRoles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data, error } = await supabase
      .from("user")
      .insert([{ email, role_id: role }]);

    if (error) {
      setMessage(`Error: ${error.message}`);
      return;
    }

    const { error: mailError } = await supabase.auth.api.inviteUserByEmail(
      email
    );

    if (mailError) {
      setMessage(`Error sending email: ${mailError.message}`);
    } else {
      setMessage("User added successfully and email sent!");
    }

    setEmail("");
    setRole("");
  };

  return (
    <div className="add-user-form">
      <h2>Add New User</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="role">Role:</label>
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="" disabled>
              Select a role
            </option>
            {roles.map((role) => (
              <option key={role.id} value={role.id}>
                {role.label}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Add User</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default AddUserForm;
