import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import "../styles/assets.css";
import AssetTypeButton from "../components/AssetTypeButton"; // Corrigé le chemin d'importation

const Assets = () => {
  const [selectedAsset, setSelectedAsset] = useState("monitor");
  const [assets, setAssets] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [view, setView] = useState("cards"); // 'cards' or 'table'
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchAssets = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase.from(selectedAsset).select("*");
        if (error) throw error;
        setAssets(data);
      } catch (error) {
        setError("Failed to fetch assets");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchBrands = async () => {
      try {
        const { data, error } = await supabase.from("brand").select("*");
        if (error) throw error;
        setBrands(data);
      } catch (error) {
        console.error("Failed to fetch brands", error);
      }
    };

    fetchAssets();
    fetchBrands();
  }, [selectedAsset]);

  const assetTypes = [
    { id: "desk", name: "Desk" },
    { id: "dica", name: "Dica" },
    { id: "docking", name: "Docking" },
    { id: "iptel", name: "Iptel" },
    { id: "kvm_switch", name: "KVM Switch" },
    { id: "monitor", name: "Monitor" },
    { id: "pc_emma", name: "PC Emma" },
    { id: "pc_infrabel", name: "PC Infrabel" },
    { id: "pc_sncb", name: "PC SNCB" },
    { id: "pc_tms", name: "PC TMS" },
    { id: "px", name: "PX" },
  ];

  const getImagePath = (type, size) => {
    if (type === "monitor" && size === 49) {
      return "/assets/Screen49.svg";
    }
    switch (type) {
      case "monitor":
        return "/assets/Screen.svg";
      case "pc_emma":
      case "pc_infrabel":
      case "pc_sncb":
      case "pc_tms":
        return "/assets/PC.svg";
      default:
        return "/assets/default.svg";
    }
  };

  const getTopRightImagePath = (type) => {
    if (type === "pc_emma" || type === "pc_tms") {
      return "/assets/Infrabel.svg";
    }
    return "/assets/default.svg";
  };

  const getBrandLabel = (brand_id) => {
    const brand = brands.find((b) => b.id === brand_id);
    return brand ? brand.label : brand_id;
  };

  const filteredAssets = assets.filter(
    (asset) =>
      asset.label &&
      asset.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="assets-page">
      <div className="assets-sidebar">
        <h2>Asset Types</h2>
        <input
          type="text"
          placeholder="Search by label"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <div className="asset-type-buttons">
          {assetTypes.map((assetType) => (
            <AssetTypeButton
              key={assetType.id}
              type={assetType}
              selected={selectedAsset === assetType.id}
              onClick={setSelectedAsset}
            />
          ))}
        </div>
      </div>
      <div className="assets-content">
        <div className="view-buttons">
          <button onClick={() => setView("cards")}>Cards</button>
          <button onClick={() => setView("table")}>Table</button>
        </div>
        <h2>
          {selectedAsset.charAt(0).toUpperCase() + selectedAsset.slice(1)}{" "}
          Assets
        </h2>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && (
          <>
            {view === "cards" && (
              <div className="asset-list">
                {filteredAssets.map((asset, index) => (
                  <div key={index} className="asset-item">
                    <div className="asset-item-header">
                      <h3 className="asset-item-title">{asset.label}</h3>
                      <img
                        className="asset-item-image-top-right"
                        src={getTopRightImagePath(selectedAsset)}
                        alt="Top right image"
                      />
                    </div>
                    <div className="asset-item-body">
                      <div className="asset-item-details">
                        {Object.entries(asset).map(
                          ([key, value]) =>
                            key !== "id" &&
                            key !== "label" &&
                            key !== "laptop" && (
                              <p key={key}>
                                <strong>
                                  {key === "brand_id" ? "brand" : key}:
                                </strong>{" "}
                                {key === "brand_id"
                                  ? getBrandLabel(value)
                                  : value}
                              </p>
                            )
                        )}
                      </div>
                      <img
                        className="asset-item-image"
                        src={getImagePath(selectedAsset, asset.size)}
                        alt={`${selectedAsset} image`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {view === "table" && (
              <table>
                <thead>
                  <tr>
                    {assets.length > 0 &&
                      Object.keys(assets[0]).map(
                        (column) =>
                          column !== "id" &&
                          column !== "laptop" && (
                            <th key={column}>
                              {column === "brand_id" ? "brand" : column}
                            </th>
                          )
                      )}
                  </tr>
                </thead>
                <tbody>
                  {filteredAssets.map((asset, index) => (
                    <tr key={index}>
                      {Object.entries(asset).map(
                        ([key, value]) =>
                          key !== "id" &&
                          key !== "laptop" && (
                            <td key={key}>
                              {key === "brand_id"
                                ? getBrandLabel(value)
                                : value}
                            </td>
                          )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Assets;
