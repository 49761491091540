import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faDesktop,
  faCog,
  faUsers,
  faFileAlt,
  faSignInAlt,
  faSun,
  faMoon,
  faPlusCircle, // Nouvelle icône ajoutée
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./Sidebar.css"; // Assurez-vous d'importer le fichier CSS

const Sidebar = ({ onLoginClick, onToggleDarkMode, darkMode }) => {
  return (
    <div className="sidebar">
      <Link to="/">
        <FontAwesomeIcon icon={faHome} className="fa-icon" />
        <span>Home</span>
      </Link>
      <Link to="/assets">
        <FontAwesomeIcon icon={faDesktop} className="fa-icon" />
        <span>Assets</span>
      </Link>
      <Link to="/settings">
        <FontAwesomeIcon icon={faCog} className="fa-icon" />
        <span>Settings</span>
      </Link>
      <Link to="/users">
        <FontAwesomeIcon icon={faUsers} className="fa-icon" />
        <span>Users</span>
      </Link>
      <Link to="/reports">
        <FontAwesomeIcon icon={faFileAlt} className="fa-icon" />
        <span>Reports</span>
      </Link>
      <Link to="/add-desk-element">
        {" "}
        {/* Nouveau lien ajouté */}
        <FontAwesomeIcon icon={faPlusCircle} className="fa-icon" />
        <span>Add </span>
      </Link>
      <div className="sidebar-footer">
        <a href="#" onClick={onToggleDarkMode}>
          <FontAwesomeIcon
            icon={darkMode ? faSun : faMoon}
            className="fa-icon"
          />
        </a>
        <a href="#" onClick={onLoginClick}>
          <FontAwesomeIcon icon={faSignInAlt} className="fa-icon" />
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
