import React, { useEffect, useState } from "react";
import { fetchUsers } from "../functions/fetchUsers";
import { supabase } from "../supabaseClient";
import "./Users.css";
import AddUserForm from "./AddUserForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const getUsers = async () => {
      const usersData = await fetchUsers();
      setTotalUsers(usersData.length);
      if (usersData.length === 0) {
        setError("Failed to fetch users or no users found");
      } else {
        setUsers(usersData);
      }
    };

    getUsers();
  }, []);

  const handleDelete = async (userId) => {
    const user = users.find((user) => user.id === userId);
    if (user.locked) {
      alert("Cannot delete locked user");
      return;
    }

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      const { error } = await supabase.from("user").delete().eq("id", userId);
      if (error) {
        console.error("Error deleting user:", error.message);
      } else {
        setUsers(users.filter((user) => user.id !== userId));
      }
    }
  };

  const handleEdit = (userId) => {
    console.log(`Editing user with id ${userId}`);
  };

  const handleSendEmail = (userEmail) => {
    console.log(`Sending email to ${userEmail}`);
  };

  const filteredUsers = users.filter(
    (user) =>
      (user.username &&
        user.username.toLowerCase().includes(filter.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(filter.toLowerCase()))
  );

  // Filter out superadmin users
  const nonSuperadminUsers = filteredUsers.filter((user) => user.role_id !== 3);

  const validatedUsers = nonSuperadminUsers.filter((user) => user.validated);
  const unvalidatedUsers = nonSuperadminUsers.filter((user) => !user.validated);

  return (
    <div className="users-container">
      <h2>Users</h2>
      <input
        type="text"
        placeholder="Filter users"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className="filter-input"
      />
      <p>Total users: {totalUsers}</p>
      {error && <p>{error}</p>}

      <h3>Validated Users</h3>
      <table className="users-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {validatedUsers.map((user) => (
            <tr key={user.id} className={user.locked ? "locked" : ""}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td className="actions">
                {!user.locked && (
                  <>
                    <button
                      onClick={() => handleEdit(user.id)}
                      className="edit-btn"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      onClick={() => handleDelete(user.id)}
                      className="delete-btn"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Unvalidated Users</h3>
      <table className="users-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {unvalidatedUsers.map((user) => (
            <tr key={user.id} className={user.locked ? "locked" : ""}>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td className="actions">
                <button
                  onClick={() => handleSendEmail(user.email)}
                  className="email-btn"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </button>
                {!user.locked && (
                  <>
                    <button
                      onClick={() => handleEdit(user.id)}
                      className="edit-btn"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      onClick={() => handleDelete(user.id)}
                      className="delete-btn"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <AddUserForm />
    </div>
  );
};

export default Users;
