import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPencilAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import "./InfoPanel.css";

const InfoPanel = ({ id, label, iptel, layoutScreen, layoutPc, onClose }) => {
  const [clickedLabel, setClickedLabel] = useState(null);

  useEffect(() => {
    const addClickListeners = () => {
      const svgObjects = document.querySelectorAll("#svg-object-screen, #svg-object-pc");

      svgObjects.forEach((svgObject) => {
        svgObject.addEventListener("load", function () {
          const svgDoc = svgObject.contentDocument;

          if (svgDoc) {
            const interactiveElements = svgDoc.querySelectorAll('*[id^="screen"], *[id^="pc"], *[id^="switch"]');

            interactiveElements.forEach(function (element) {
              element.addEventListener("click", function () {
                const elementId = element.getAttribute("id");

                if (elementId) {
                  setClickedLabel(`${elementId} : ID non connu, veuillez mettre à jour`);
                }
              });
            });
          }
        });
      });
    };

    addClickListeners();
  }, [layoutScreen, layoutPc]);

  const layoutScreenSrc = layoutScreen ? `/assets/layout${layoutScreen}.svg` : null;
  const layoutPcSrc = layoutPc ? `/assets/desk${layoutPc}pc.svg` : null;

  return (
    <div id="info-panel" className="info-panel open">
      <div className="header">
        <button id="close-btn" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>{id}</h2>
        {label && <h3>{label}</h3>}
      </div>
      <div id="info-content">
        {iptel && (
          <p>
            <FontAwesomeIcon icon={faPhoneAlt} /> {iptel}
          </p>
        )}
        <div className="layout-container">
          {layoutScreenSrc && (
            <div className="layout-screen">
              <object
                id="svg-object-screen"
                type="image/svg+xml"
                data={layoutScreenSrc}
                aria-label={`Layout Screen ${layoutScreen}`}
              />
            </div>
          )}
          {layoutPcSrc && (
            <div className="layout-pc">
              <object
                id="svg-object-pc"
                type="image/svg+xml"
                data={layoutPcSrc}
                aria-label={`Layout PC ${layoutPc}`}
              />
            </div>
          )}
        </div>
        {/* Affichage du texte en bas après les divs */}
        {clickedLabel && <div className="clicked-label">{clickedLabel}</div>}
      </div>
      <button id="edit-btn">
        <FontAwesomeIcon icon={faPencilAlt} />
      </button>
    </div>
  );
};

export default InfoPanel;