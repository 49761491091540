import React, { useEffect, useState } from "react";
import InfoPanel from "./InfoPanel";
import * as d3 from "d3";
import { supabase } from "../supabaseClient";

const SvgManagement = () => {
  const [selectedElement, setSelectedElement] = useState(null);
  const [elementData, setElementData] = useState({});

  useEffect(() => {
    const svgObject = document.getElementById("svg-object");
    svgObject.addEventListener("load", function () {
      const svgDoc = svgObject.contentDocument;
      const svgRoot = svgDoc.documentElement;

      if (!svgRoot) {
        console.error("SVG root element not found.");
        return;
      }

      const g = svgDoc.createElementNS("http://www.w3.org/2000/svg", "g");

      while (svgRoot.firstChild) {
        g.appendChild(svgRoot.firstChild);
      }

      svgRoot.appendChild(g);

      const bbox = g.getBBox();
      svgRoot.setAttribute(
        "viewBox",
        `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
      );
      svgRoot.setAttribute("preserveAspectRatio", "xMidYMid meet");

      svgRoot.style.width = "100%";
      svgRoot.style.height = "100%";

      const svg = d3.select(g);
      const zoom = d3
        .zoom()
        .scaleExtent([1, 10])
        .on("zoom", function (event) {
          svg.attr("transform", event.transform);
        });

      d3.select(svgRoot).call(zoom).call(zoom.transform, d3.zoomIdentity);

      const interactiveElements = svgDoc.querySelectorAll('[id*="WPT"]');

      interactiveElements.forEach(function (element) {
        element.addEventListener("click", async function (e) {
          const elementId = element.getAttribute("id");
          console.log("Element ID clicked:", elementId);

          setSelectedElement(elementId);

          const { data, error } = await supabase
            .from("desk")
            .select("label, iptel_id, layout_screen, layout_pc") // Récupère les valeurs de layout
            .eq("floc", elementId);

          if (error) {
            console.error("Error fetching data:", error.message);
            setElementData({});
          } else if (data.length === 0) {
            console.log(`No data found for floc: ${elementId}`);
            setElementData({});
          } else {
            console.log("Data retrieved for selected element:", data[0]);
            setElementData(data[0]);
          }
        });
      });
    });
  }, []);

  return (
    <div>
      <div id="svg-container">
        <object
          id="svg-object"
          type="image/svg+xml"
          data="plans/OCC_RCC_FBMZ.svg"
        />
      </div>
      {selectedElement && (
        <InfoPanel
          id={selectedElement}
          label={elementData.label}
          iptel={elementData.iptel_id}
          layoutScreen={elementData.layout_screen}
          layoutPc={elementData.layout_pc}
          onClose={() => setSelectedElement(null)}
        />
      )}
    </div>
  );
};

export default SvgManagement;