import React from "react";

const Reports = () => {
  return (
    <div>
      <h2>Reports</h2>
      <p>View reports here.</p>
    </div>
  );
};

export default Reports;
