import { supabase } from "../supabaseClient";

export const fetchRoles = async () => {
  const { data, error } = await supabase.from("role").select("*");
  if (error) {
    console.error("Error fetching roles:", error.message);
    return [];
  }
  return data;
};
