import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ButtonGroup from "./components/ButtonGroup";
import Home from "./pages/Home";
import Assets from "./pages/Assets";
import Settings from "./pages/Settings";
import Users from "./pages/Users";
import Reports from "./pages/Reports";
import SvgManagement from "./components/SvgManagement";
import Sidebar from "./components/Sidebar";
function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>Asset Vision</h1>
        </header>
        <div className="main-content">
          <Sidebar />
          <Routes>
            <Route exact path="/" element={<ButtonGroup />} />
            <Route path="/app" element={<SvgManagement />} />
            <Route path="/home" element={<Home />} />
            <Route path="/assets" element={<Assets />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/users" element={<Users />} />
            <Route path="/reports" element={<Reports />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
