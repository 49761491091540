import React from "react";
import { useNavigate } from "react-router-dom";
import "./ButtonGroup.css"; // Assurez-vous d'importer le fichier CSS

const ButtonGroup = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <div className="button-group">
      <div className="category">
        <h2>OCC / RC</h2>
        <button onClick={() => handleButtonClick("/app")}>OCC Bruxelles</button>
        <button onClick={() => handleButtonClick("/app")}>OCC Mons</button>
        <button onClick={() => handleButtonClick("/app")}>OCC Namur</button>
        <button onClick={() => handleButtonClick("/app")}>OCC Gent</button>
        <button onClick={() => handleButtonClick("/app")}>OCC Antwerpen</button>
        <button onClick={() => handleButtonClick("/app")}>RCC</button>
      </div>
      <div className="category">
        <h2>CR / SOC</h2>
        <button onClick={() => handleButtonClick("/app")}>ACR</button>
        <button onClick={() => handleButtonClick("/app")}>BCR</button>
        <button onClick={() => handleButtonClick("/app")}>LCR</button>
        <button onClick={() => handleButtonClick("/app")}>SOC</button>
      </div>
    </div>
  );
};

export default ButtonGroup;
